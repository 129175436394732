<template>
  <base-section id="about-our-product">
    <base-section-heading
      icon="mdi-vuetify"
      title="About pub trails"
    />

    <v-container>
      <v-row justify="center">
        <v-spacer />
        <v-col
          cols="10"
          lg="8"
        >
          <base-body>
            Cork Pub Trails is about finding the best night out, every night. Cork is teeming with fantastic bars just waiting to be explored.
            The beauty of Cork is that is a city but is as accessible as a town. Cork is a very walkable city, with lots of pedestrianisation,
            a flat city center, plenty of bridges, public transport options and not too much distance to cover. The city centre contains well
            over 100 different bars, with something for everyone. The best advice we can give is to get out and see as much as you can!
          </base-body>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Plan Your Night',
          subtitle: 'Simplify',
          text: 'Take the hassle out of organising your night, simply pick the type of night you are looking for, and choose a trail to match your mood',
          callout: '01',
        },
        {
          title: 'Find new experiences',
          subtitle: 'Discover',
          text: 'Unsure of where to go, or what there is to see in Cork? There is something for everyone and every occasion, you just need to know where to look. Our trails will suit any idea that you might fancy',
          callout: '02',
        },
        {
          title: 'Mix up your Routine',
          subtitle: 'Diversify',
          text: 'There is nothing better than an old reliable or favoured haunt, but variety is the spice of life so why not try some of the over 100 bars in Cork City for something new?',
          callout: '03',
        },
      ],
    }),
  }
</script>
